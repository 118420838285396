import React from "react"
import { taskManagementAndReportingPage } from "../../Content/FeatureInnerPages"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeatureDetailsPage from "../../components/pages/FeatureDetails/FeatureDetailsPage"

const TaskManagementAndReporting = () => {
  return (
    <Layout>
      <Seo
        title="Improve Task Management & Reporting with Synkli for Accountants"
        description={`Enhance task management and reporting with Synkli. Assign tasks, track progress, and get insights to effortlessly improve your accounting practice management.`}
      />

      <FeatureDetailsPage data={taskManagementAndReportingPage} />
      
    </Layout>
  )
}

export default TaskManagementAndReporting
